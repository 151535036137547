import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f7fafc;
`;

export const Header = styled.div`
  background-color: #1e1e1e;
  width: 100%;
  text-align: center;
  color: #ffffff;
`;

export const SwapBox = styled.div` 
  max-width: 100%;
 width:465px;
`;

export const SwapInput = styled.input`
 
  margin-bottom: 5px;
background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 2px;
    border-top: 1px solid #8f8e8e;
    box-sizing: border-box;
    width:100%;
    padding:6px 6px 7px;
    margin-top:5px;
`;

export const SwapButton = styled.button`
    display: block;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    font-size: 18px;
    transition: 0.3s ease all;
    color: white;
    border: 1px solid #000000;
    padding: 14px;
    transition: 0.3s ease all;
    background: rgb(58, 54, 68);
    box-shadow: inset 0px 0px 3px 1px rgba(0, 0, 0, 0.4392156863);
    background: linear-gradient(0deg, rgb(88, 192, 237) 0%, rgb(45, 62, 159) 46%, rgb(45, 62, 159) 48%, rgb(100, 120, 195) 60%, rgb(135, 162, 223) 100%);
    border-radius: 5px;
    min-width: 180px;
    text-align: center;
        width: 100%;
    margin-top: 10px;
  
  &:hover {
    opacity:0.85;
  }
    &:active{
    opacity:0.7;}
`;

export const TokenSelect = styled.select`
  width: 100%;
 
 
  margin-bottom: 0px;
 
  outline: none;


      -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTciIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJhIiB4MT0iMCUiIHkxPSIwJSIgeDI9IjEwMCUiIHkyPSIwJSI+PHN0b3Agb2Zmc2V0PSIwJSIgc3R5bGU9InN0b3AtY29sb3I6IzMzMztzdG9wLW9wYWNpdHk6MSIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3R5bGU9InN0b3AtY29sb3I6I2FhYTtzdG9wLW9wYWNpdHk6MSIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxwYXRoIGQ9Ik0xMSA2SDR2MWgxdjFoMXYxaDF2MWgxVjloMVY4aDFWN2gxVjZaIiBmaWxsPSJ1cmwoI2EpIi8+PC9zdmc+), linear-gradient(to bottom, #f2f2f2 45%, #ebebeb 45%, #cfcfcf);
    background-position: 100%;
    background-repeat: no-repeat;
    border: 1px solid;
    border-color: #8f8e8e;
    border-radius: 3px;
    box-shadow: inset 0 0 0px 1px #fffc;
    box-sizing: border-box;
    color: #222; 
    padding: 5px 30px 5px 6px;
    position: relative;
`;

export const ImportButton = styled.button`
  margin-top: 10px;
  padding: 10px;
  background-color: #efefef;
  color: #333;
  border-radius: 12px;
  border: none;
  cursor: pointer;

  &:hover {
    background-color: #dedede;
  }
`;
