import React, { useState, useEffect } from 'react';

const Bones = ({ signer, refreshTrigger }) => {
  const [bones, setBones] = useState(null); // Initialize with null or 0

  useEffect(() => {
    if (signer) {
      const fetchBones = async () => {
        try {
          const response = await fetch(`https://dashboard.darkark.capital:3099/api/bones/${signer.address}`);
          
          if (!response.ok) {
            throw new Error('Failed to fetch bones data');
          }

          const data = await response.json();
          setBones(data.bones); // Update the bones state with the fetched value
        } catch (err) {
          console.log(err);
        } 
      };

      fetchBones();
    }
  }, [signer, refreshTrigger]);

  if (!signer) {
    return null; // If no signer, don't render anything
  }

  return (
    <div style={{ margin: 20, marginTop:0, maxWidth: '100%' }}>
      <div className="desc" style={{ paddingTop: 10, width: '100%' }}>
        <div className="window-body has-space">
          <div>🦴 x{bones || 0}</div> {/* Display bones */}
        </div>
      </div>
    </div>
  );
};

export default Bones;
