import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { scroller } from 'react-scroll';  // Import react-scroll
import logo from '../images/asset.png';  // Adjust path for logo


const Header = () => {
  const location = useLocation(); // Get the current location (to know if we are on the home page)
  const navigate = useNavigate(); // For navigation to the home page

  // Function to scroll to the tokenomics section
  const scrollToTokenomics = () => {
    scroller.scrollTo('tokenomics__wrapper', {
      duration: 800,
      delay: 0,
      smooth: 'easeInOutQuart',
    });
  };

  const scrollToContact = () => {
    scroller.scrollTo('join__community', {
      duration: 800,
      delay: 0,
      smooth: 'easeInOutQuart',
    });
  };

  // Handle click on Tokenomics
  const handleTokenomicsClick = () => {
    if (location.pathname === '/') {
      // If on the homepage, just scroll down
      scrollToTokenomics();
    } else {
      // If not on the homepage, navigate to home and then scroll
      navigate('/');
      setTimeout(() => {
        scrollToTokenomics();
      }, 100); // Small delay to ensure the page loads before scrolling
    }
  };

  const handleContactClick = () => {
    if (location.pathname === '/') {
      scrollToContact(); // Scroll if on homepage
    } else {
      navigate('/'); // Navigate to homepage then scroll
      setTimeout(() => {
        scrollToContact();
      }, 100);
    }
  };

  return (
    <header>
      <div>
        <div className="outer__header">
          <div className="logo">
            <Link to="/">
              <img src={logo} alt="logoheader" />
            </Link>
          </div>
          <div className="menu">
            <ul> 
              <li><a href="/">HOME</a></li>
              <li><a onClick={handleTokenomicsClick} href="#">TOKENOMICS</a></li> {/* Updated to handle scroll */}
              <li><a onClick={handleContactClick} href="#">About</a></li>
              <li><a href="https://t.me/VistaRovererc20" target="_blank">TELEGRAM</a></li>
              <li><a href="https://x.com/VistaRover"  target="_blank">TWITTER</a></li> 
            </ul>
          </div>
     
          <div className="btn">
            <Link to="/swap">Launch App</Link>
          </div>
          <a href="/" className="backh">
            Back to homepage
          </a>
        </div>
      </div>
    </header>
  );
};

export default Header;
