import React, { useState, useEffect } from 'react';
import { parseUnits, formatUnits, ethers } from 'ethers';
import { fetchBalance } from '../services/fetchBalance';
import { fetchFees } from '../services/fetchFees';
import { fetchEthPriceInUSDC } from '../services/fetchEthPrice';
import { SwapBox, SwapInput, SwapButton, TokenSelect } from '../styles';
import { getMainRouterContract, getWrapRouterContract } from '../contracts/routerContract';
import { showNotification } from '../services/notificationService';
import { erc20ABI } from '../contracts/erc20ABI';
import ImportTokens from '../components/ImportTokens';


const SwapTokens = ({ signer, tokens, getTokens, refreshBones  }) => {
  const [tokenFrom, setTokenFrom] = useState('ETH');
  const [tokenTo, setTokenTo] = useState('ROVER'); 
  const [amountIn, setAmountIn] = useState('');
  const [balance, setBalance] = useState(0);
  const [slippage, setSlippage] = useState(1);
  const [fees, setFees] = useState({ buyFee: 0, sellFee: 0 });
  const [estimatedOutput, setEstimatedOutput] = useState(0);
  const [webSiteFee, setWebSiteFee] = useState(0);
  

  useEffect(() => {
    const fetchFees = async () => {
      try {
        const contract = getWrapRouterContract();
        const fee1 = await contract.feeValue1();
        const fee2 = await contract.feeValue2();
        const fee = Number(fee1) + Number(fee2);
        setWebSiteFee(fee);
      } catch (error) {
        console.log(error)
      }
    };
    
    fetchFees();
  }, []);
  
  useEffect(() => {
    if (amountIn && tokenFrom && tokenTo) {
      calculatePrice();
    }
    const interval = setInterval(() => {
      if (amountIn && tokenFrom && tokenTo) {
         calculatePrice();
       }
     }, 5000); // Refresh every 5 seconds
  
    return () => clearInterval(interval); // Cleanup on unmount
  }, [amountIn, tokenFrom, tokenTo]);

 

  const onTokenImported = (importedToken) => {
    setTokenFrom(importedToken); // Automatically select the imported token in the "from" field
    getTokens();

  };

  useEffect(() => {
    const fetchAndSetData = async () => {
      try {
        if (!signer) return;

        // Fetch balance for tokenFrom
        const balance = await fetchBalance(tokens[tokenFrom], signer);
        setBalance(balance);

        // Always pull fees from the non-ETH token
        if (tokenFrom === 'ETH' && tokenTo !== 'ETH') {
          const feeData = await fetchFees(tokens[tokenTo].contractAddress, tokens[tokenFrom].contractAddress, signer);
          setFees(feeData);
        } else if (tokenTo === 'ETH' && tokenFrom !== 'ETH') {
          const feeData = await fetchFees(tokens[tokenFrom].contractAddress, tokens[tokenTo].contractAddress, signer);
          setFees(feeData);
        } else {
          setFees({ buyFee: 0, sellFee: 0 }); // No fees if both are ETH
        }
      } catch (error) {
        console.error('Error fetching balance or fees:', error);
        showNotification('Error fetching balance or fees.', 'error', 5000);
      }
    };

    if (signer && tokenFrom && tokenTo) {
      fetchAndSetData();
    }
  }, [signer, tokenFrom, tokenTo]);

  const handleMax = () => setAmountIn(balance);

  const handleSlippageChange = (e) => {
    const value = parseFloat(e.target.value);
    setSlippage(value <= 50 && value >= 0.1 ? value : value > 50 ? 50 : 0.1);
  };

  const handleTokenFromChange = (e) => {
    const newTokenFrom = e.target.value;
    setTokenFrom(newTokenFrom);
    // Ensure the selected tokens are different
    if (newTokenFrom === tokenTo) {
      const firstAvailableToken = Object.keys(tokens).find((key) => key !== newTokenFrom);
      setTokenTo(firstAvailableToken); // Automatically set a different token
    }
  };

  const handleTokenToChange = (e) => {
    const newTokenTo = e.target.value;
    setTokenTo(newTokenTo);
    // Ensure the selected tokens are different
    if (newTokenTo === tokenFrom) {
      const firstAvailableToken = Object.keys(tokens).find((key) => key !== newTokenTo);
      setTokenFrom(firstAvailableToken); // Automatically set a different token
    }
  };

  const swapTokens = () => {
    const temp = tokenFrom;
    setTokenFrom(tokenTo);
    setTokenTo(temp);
  };

  const calculatePrice = async () => {
    const amountInNumber = parseFloat(amountIn);
    // Prevent calculating with invalid values
    if (!amountIn || isNaN(amountInNumber) || amountInNumber <= 0) {
      setEstimatedOutput(''); // Clear the output if the input is invalid
      return;
    }

    try {
      const contract = getMainRouterContract(signer);
      const path = [tokens[tokenFrom].contractAddress, tokens[tokenTo].contractAddress];

      const estimatedOut = await contract.getAmountsOut(
        parseUnits(amountIn, parseInt(tokens[tokenFrom].decimals)),
        path
      );

      setEstimatedOutput(formatUnits(estimatedOut[1], parseInt(tokens[tokenTo].decimals)));

    } catch (error) {
      // Check if the error is related to unsupported token combinations
      if (error?.code === 'CALL_EXCEPTION') {
        showNotification('Token combination not supported. Please select different tokens.', 'error', 5000);
      } else {
        console.error('Error calculating price:', error);
        showNotification('Error calculating price. Please try again.', 'error', 5000);
      }
    }
  };


  const handleSwap = async () => {
    if (!amountIn || !signer || !estimatedOutput) {
      showNotification('Please fill in the swap details and connect your wallet.', 'error', 5000);
      return;
    }

    try {

      
      const buyFeeInEth = tokenFrom === 'ETH'
        ? await fetchEthPriceInUSDC(fees.sellFee, signer) // ETH is tokenFrom, so calculate sell fee in ETH using tokenTo fee
        : await fetchEthPriceInUSDC(fees.buyFee, signer); // Otherwise, calculate buy fee in ETH when ETH is not tokenFrom

      const sellFeeInEth = tokenTo === 'ETH'
        ? await fetchEthPriceInUSDC(fees.buyFee, signer) // ETH is tokenTo, so calculate buy fee in ETH using tokenFrom fee
        : await fetchEthPriceInUSDC(fees.sellFee, signer); // Otherwise, calculate sell fee in ETH when ETH is not tokenTo

      const contract = getWrapRouterContract(signer);
      const contractMain = getMainRouterContract(signer);
      const tokenContract = new ethers.Contract(tokens[tokenFrom].contractAddress, erc20ABI, signer);
      const userAddress = await signer.getAddress();
      const deadline = Math.floor(Date.now() / 1000) + 60 * 20; // 20-minute deadline
      const webSiteFeeToEth = await contractMain.usdcToEth(webSiteFee);
      const decimalsTo = parseInt(tokens[tokenTo].decimals);
      const decimalsFrom =  parseInt(tokens[tokenFrom].decimals);

      if (tokenFrom === 'ETH') {
        // Swap ETH -> Token
        
        const adjustedEstimatedOutput = (1 - slippage / 100) * estimatedOutput; // Slippage-adjusted output
       
        const amountOutMin = parseUnits(adjustedEstimatedOutput.toFixed(decimalsTo), decimalsTo);
        const path = [tokens.ETH.contractAddress, tokens[tokenTo].contractAddress];
        const amountInBigInt = ethers.toBigInt(parseUnits(amountIn, 18));
        const totalAmountInEth = amountInBigInt + buyFeeInEth + webSiteFeeToEth; // Add the fee in ETH

        const tx = await contract.swapExactETHForTokensSupportingFeeOnTransferTokens(
          amountOutMin,
          path,
          await signer.getAddress(),
          deadline,
          { value: totalAmountInEth}
        );

        await tx.wait();
        showNotification('Swap successful!', 'success', 5000);

        await updateBones(userAddress, refreshBones);

      } else if (tokenTo === 'ETH') {
        
        const allowance = await tokenContract.allowance(userAddress, contract.target);
        const amountInBigInt = ethers.toBigInt(parseUnits(amountIn.toString(), parseInt(decimalsFrom)));

        // If the allowance is insufficient, approve the contract to spend tokens
        if (allowance < amountInBigInt) {
          const approveTx = await tokenContract.approve(contract.target, amountInBigInt);
          await approveTx.wait(); // Wait for the approval to complete
          showNotification('Token spending approved!', 'success', 5000);
        }

        // Swap Token -> ETH
        const adjustedEstimatedOutput = (1 - slippage / 100) * estimatedOutput; // Slippage-adjusted output

        const amountOutMin = parseUnits(adjustedEstimatedOutput.toFixed(decimalsTo), decimalsTo); // Convert to string
        const path = [tokens[tokenFrom].contractAddress, tokens.ETH.contractAddress];
        const totalFeeInEth = sellFeeInEth + webSiteFeeToEth;

        const tx = await contract.swapExactTokensForETHSupportingFeeOnTransferTokens(
          amountInBigInt,
          amountOutMin,
          path,
          userAddress,
          deadline,
          { value: totalFeeInEth}
        );

        await tx.wait();
        showNotification('Swap successful!', 'success', 5000);

        await updateBones(userAddress, refreshBones);

      } else {
        showNotification('Unsupported swap direction. Please check your tokens.', 'error', 5000);
      }

      
      const updatedBalance = await fetchBalance(tokens[tokenFrom], signer);
      setBalance(updatedBalance);

    } catch (error) {

      const errorMessage = error?.error?.message || error.message || 'Swap failed, please try again.';
      showNotification(errorMessage, 'error', 5000);

    }
  };

  const updateBones = async (walletAddress, refreshBones) => {
    try {
      if (!walletAddress) return;
  
      // Add 1 bone per successful transaction
      const numberOfBones = 1;
  
      // Make a POST request to your backend API to update or insert bones
      const response = await fetch(`https://dashboard.darkark.capital:3099/api/bones/${walletAddress}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ numberOfBones }),
      });
  
      if (!response.ok) {
        throw new Error('Failed to update bones');
      }
  
      // After updating, fetch the updated bones from the backend
      const bonesResponse = await fetch(`https://dashboard.darkark.capital:3099/api/bones/${walletAddress}`);
      const bonesData = await bonesResponse.json();
  
  
      // Call the refreshBones function (passed from the parent) to update the frontend state
      refreshBones(bonesData.bones);
    } catch (error) {
      console.error('Error updating bones:', error);
      showNotification('Failed to update bones', 'error', 5000);
    }
  };
  

  return (
    <SwapBox>
      <div className="desc ">
      <div className="title-bar">
        <div className="title-bar-text">Swap Tokens</div>
      </div>
      <div className="window-body has-space">
      <div>
        <TokenSelect value={tokenFrom} onChange={handleTokenFromChange}>
          {Object.keys(tokens).map((tokenKey) => {
            return (
              <option key={tokenKey} value={tokenKey}>
                {tokens[tokenKey].symbol}
              </option>
            )
          }
          )}
        </TokenSelect>
        {tokens[tokenFrom]?.liquidity === false && 'No liquidity'}
        
      </div>
      <div className="maxwrap">
        <SwapInput
          type="text"
          value={amountIn}
          onChange={(e) => setAmountIn(e.target.value)}
          placeholder="Amount to swap"
        />
        <button className="max" onClick={handleMax}>Max</button>
        <p>Balance: {balance}</p>
      </div>
      <ImportTokens  onTokenImported={onTokenImported} />
      <div className="swap">
      <svg onClick={swapTokens} xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="#222222" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><line x1="12" y1="5" x2="12" y2="19"></line><polyline points="19 12 12 19 5 12"></polyline></svg>
      </div>
      <div>
        <TokenSelect value={tokenTo} onChange={handleTokenToChange}>
          {Object.keys(tokens)
            .filter((tokenKey) => tokenKey !== tokenFrom) // Exclude the selected tokenFrom
            .map((tokenKey) => (
              <option key={tokenKey} value={tokenKey}>
                {tokens[tokenKey].symbol}
              </option>
            ))}
        </TokenSelect>
        
        {tokens[tokenTo]?.liquidity === false && 'No liquidity'}
        
        <SwapInput
          type="text"
          value={estimatedOutput}
          onChange={(e) => setAmountIn(e.target.value)}
          placeholder="Amount to swap"
        />  
      </div>
      <div className="fdivider"></div>
      <div className="slippage">
        <label>Slippage Tolerance (%):</label>
        <SwapInput
          type="number"
          value={slippage}
          onChange={handleSlippageChange}
          min="1"
          max="50"
          step="1"
        />
      </div>
      <div className="fees">Buy Fee: ${Number(fees.buyFee) || 0}, Sell Fee: ${Number(fees.sellFee) || 0}</div>
      <SwapButton onClick={handleSwap}>Swap</SwapButton>
      </div>
      </div>
    </SwapBox>
  );
};

export default SwapTokens;
