import { Contract, formatUnits } from 'ethers';
import { erc20ABI } from '../contracts/erc20ABI';

export const fetchBalance = async (token, signer) => {
  const provider = signer.provider;
  let balance;

  if (token.symbol === 'ETH') {
    const rawBalance = await provider.getBalance(await signer.getAddress());
    balance = formatUnits(rawBalance, 18);  // ETH has 18 decimals
  } else {
    const tokenContract = new Contract(token.contractAddress, erc20ABI, signer);
    const rawBalance = await tokenContract.balanceOf(await signer.getAddress());
    balance = formatUnits(rawBalance, parseInt(token.decimals));
  }

  return balance;
};
