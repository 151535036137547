import React, { useEffect } from 'react';
import { SwapButton } from '../styles';
import { useConnectModal } from '@rainbow-me/rainbowkit';
import { useWalletClient, useAccount } from 'wagmi';
import { BrowserProvider, JsonRpcSigner } from 'ethers'

const WalletConnect = ({ setSigner }) => {
  const { openConnectModal } = useConnectModal();
  const { address } = useAccount();
  const { data: walletClient } = useWalletClient();

  useEffect(() => {
    if (walletClient) {
      const signer = clientToSigner(walletClient);
      setSigner(signer);
    }
  }, [walletClient]);

  const clientToSigner = (client) => {
    const { account, chain, transport } = client
    const network = {
      chainId: chain.id,
      name: chain.name,
      ensAddress: chain.contracts?.ensRegistry?.address,
    }
    const provider = new BrowserProvider(transport, network)
    const signer = new JsonRpcSigner(provider, account.address)
    return signer
  }

  return (
    <div className="wallet-connect" style={{ margin: 20 }}>
      {address ? (
        <div className="desc">
          <div className="window-body has-space">
            <div>Connected: {address}</div>
          </div>
        </div>
      ) : (
        <SwapButton onClick={openConnectModal}>Connect Wallet</SwapButton>
      )}
    </div>
  );
};

export default WalletConnect;
