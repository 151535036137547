import { Contract } from 'ethers';

const pairABI = [
  {
    constant: true,
    inputs: [],
    name: 'buyTotalFee',
    outputs: [{ name: '', type: 'uint8' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    constant: true,
    inputs: [],
    name: 'sellTotalFee',
    outputs: [{ name: '', type: 'uint8' }],
    stateMutability: 'view',
    type: 'function',
  },
];

// Fetch buy and sell fees
export const getBuyFee = async (pairAddress, signer) => {
  const pairContract = new Contract(pairAddress, pairABI, signer);
  const buyFee = await pairContract.buyTotalFee();
  return buyFee;
};

export const getSellFee = async (pairAddress, signer) => {
  const pairContract = new Contract(pairAddress, pairABI, signer);
  const sellFee = await pairContract.sellTotalFee();
  return sellFee;
};
