import React, { useState, useEffect, useRef } from 'react';
import { ethers, ZeroAddress } from "ethers";
import { erc20ABI } from '../contracts/erc20ABI';
import { factoryABI } from '../contracts/factoryABI';
import { SwapInput, SwapButton } from '../styles';

const ImportTokens = ({ onTokenImported }) => {
  const [importTokenAddress, setImportTokenAddress] = useState('');
  const [isActive, setIsActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false); 

  const wrapperRef = useRef(null);

  const importToken = async () => {
    try {
      // Check if MetaMask is available
      if (!window.ethereum) {
        console.error('MetaMask not detected');
        return;
      }

      await window.ethereum.request({ method: 'eth_requestAccounts' });
      setIsLoading(true);
  
      // Initialize the provider
      const provider = new ethers.BrowserProvider(window.ethereum);
  
      // Initialize token contract
      const tokenContract = new ethers.Contract(importTokenAddress, erc20ABI, provider);
  
      // Fetch token symbol and decimals
      const tokenSymbol = await tokenContract.symbol();
      const decimals = await tokenContract.decimals();
  
      // Initialize factory contract for liquidity check
      const factoryContract = new ethers.Contract('0x9a27cb5ae0B2cEe0bb71f9A85C0D60f3920757B4', factoryABI, provider);
      const pairAddress = await factoryContract.getPair('0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2', importTokenAddress);
  
      // Get token list from localStorage
      let tokens = localStorage.getItem('tokenList');
      tokens = tokens ? JSON.parse(tokens) : {};
  
      // Add the imported token to the token list
      tokens[tokenSymbol] = {
        symbol: tokenSymbol,
        decimals: decimals.toString(),
        contractAddress: importTokenAddress.toString(),
        liquidity: pairAddress !== ZeroAddress
      };
 
      // Save updated token list to localStorage
      localStorage.setItem('tokenList', JSON.stringify(tokens));
  
      // Call the callback to select the imported token
      onTokenImported(tokenSymbol);
  
      // Clear input and close the modal after import completes
      setImportTokenAddress('');
      setIsActive(false);
      setIsLoading(false);  // Stop loading after process is complete

    } catch (error) {
      console.error('Error importing token:', error);
      setIsLoading(false);  // Stop loading in case of error
      setIsActive(false);
    }
  };
  

  // Handle click outside to close the dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setIsActive(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  return (
    <div className="imptokenwrap" ref={wrapperRef}>
      <a onClick={() => setIsActive(!isActive)}>Import Tokens</a>
      <div className={`desc imp-token ${isActive ? 'active' : ''}`}>
        <div className="title-bar">
          <div className="title-bar-text">Import Tokens</div>
        </div>
        <div className="window-body has-space">
          <SwapInput 
            value={importTokenAddress} 
            onChange={(e) => setImportTokenAddress(e.target.value)} 
          />
           <SwapButton onClick={importToken} disabled={isLoading}>
            {isLoading ? 'Importing...' : 'Import'}
          </SwapButton>
        </div>
      </div>
    </div>
  );
};

export default ImportTokens;
