import React, { useState, useEffect } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import WalletConnect from '../components/WalletConnect';
import Bones from '../components/Bones';
import SwapTokens from '../components/SwapTokens';
import { tokens } from '../contracts/tokens'; // Token list from contracts

const SwapPage = () => {
  const [signer, setSigner] = useState(null);
  const [tokenList, setTokenList] = useState({});
  const [refreshTrigger, setRefreshTrigger] = useState(0);
  
  useEffect(() => {
    getTokens();
  }, []);

  const refreshBones = () => {
    setRefreshTrigger((prev) => prev + 1); // Trigger a refresh by updating refreshTrigger
  };

 
  const getTokens = () => {
    let tokenList = localStorage.getItem('tokenList');
 
    if (tokenList && Object.keys(tokenList).length > 2 ) {
      let storedTokenList = JSON.parse(tokenList);

      const mergedTokenList = {
        ...storedTokenList, 
        ...tokens,  
      };

      if (JSON.stringify(storedTokenList) !== JSON.stringify(mergedTokenList)) {
        localStorage.setItem('tokenList', JSON.stringify(mergedTokenList));
      }

      tokenList = storedTokenList;

    } else {
      localStorage.setItem('tokenList', JSON.stringify(tokens));
      tokenList = tokens;
    }
    setTokenList(tokenList);
  };
  

  return (
    <>
      <div className="hero__section swap_page">
        <Header />
        <div className="container">
          <WalletConnect setSigner={setSigner} />
          <Bones signer={signer} refreshTrigger={refreshTrigger} />
          <SwapTokens signer={signer} tokens={tokenList} getTokens={getTokens} refreshBones={refreshBones} /> 
        </div>
      </div>
      <Footer />
    </>
  );
};

export default SwapPage;
