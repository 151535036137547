import { getPairAddress } from '../contracts/factoryContract';
import { getBuyFee, getSellFee } from '../contracts/pairContract';

export const fetchFees = async (tokenA, tokenB, signer) => {
  const pairAddress = await getPairAddress(tokenA, tokenB, signer);

  const buyFee = await getBuyFee(pairAddress, signer);
  const sellFee = await getSellFee(pairAddress, signer);

  return { buyFee, sellFee };
};
