import Noty from 'noty';
import 'noty/lib/noty.css';

export const showNotification = (text, type = 'info', timeout = 3000) => {
  new Noty({
    text,
    type, // success, error, warning, info
    layout: 'topRight', // Or other positions: topLeft, bottomRight, etc.
    timeout, // Auto-hide after X milliseconds
  }).show();
};
