import { Contract } from 'ethers';
import { factoryABI } from '../contracts/factoryABI';

const factoryAddress = '0x9a27cb5ae0B2cEe0bb71f9A85C0D60f3920757B4';

export const getPairAddress = async (tokenA, tokenB, signer) => {
  const factoryContract = new Contract(factoryAddress, factoryABI, signer);
  const pairAddress = await factoryContract.getPair(tokenA, tokenB);
  return pairAddress;
};
