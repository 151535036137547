export const tokens = {
  ETH: {
    symbol: 'ETH',
    decimals: 18,
    contractAddress: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
    liquidity: true
  },
  ROVER: {
    symbol: 'ROVER',
    decimals: 18,
    contractAddress: '0x6aF84e3e9Fa8486b5cBb67c55ED1E7D9372a6d23',
    liquidity: true
  },
  BONZI: {
    symbol: 'BONZI',
    decimals: 18,
    contractAddress: '0xD6175692026BcD7Cb12A515e39cF0256eF35cb86',
    liquidity: true
  },
  CLIPPY: {
    symbol: 'CLIPPY',
    decimals: 9,
    contractAddress: '0x6304563F6639c077B519209f38611C0F67D6f10d',
    liquidity: true
  },
  NINJA: {
    symbol: 'NINJA',
    decimals: 18,
    contractAddress: '0xF1a732FEEEb43450E4B35b54ea9D04eD994391AA',
    liquidity: true
  },
  USDT: {
    symbol: 'USDT',
    decimals: 6,
    contractAddress: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
    liquidity: true
  },
  VISTA: {
    symbol: 'VISTA',
    decimals: 18,
    contractAddress: '0xC9bCa88B04581699fAb5aa276CCafF7Df957cbbf',
    liquidity: true
  },  
};
