import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LandingPage from './pages/LandingPage'; // Landing Page
import SwapPage from './pages/SwapPage'; // Swap Page
import './css/main.css';

import '@rainbow-me/rainbowkit/styles.css';
import {
  getDefaultConfig,
  lightTheme,
  RainbowKitProvider,
} from '@rainbow-me/rainbowkit';
import { WagmiProvider } from 'wagmi';
import {
  mainnet
} from 'wagmi/chains';
import {
  QueryClientProvider,
  QueryClient,
} from "@tanstack/react-query";

function App() {
  const config = getDefaultConfig({
    appName: 'vista',
    projectId: 'f10da068f43fd06f970d77a0d9c5d47e',
    chains: [mainnet],
    ssr: false, // If your dApp uses server side rendering (SSR)
  });
  const queryClient = new QueryClient();
  return (
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>
        <RainbowKitProvider
          modalSize="compact"
          theme={lightTheme({
            borderRadius: 'small',
          })}
        >
          <Router>
            <Routes>
              {/* Landing page route */}
              <Route path="/" element={<LandingPage />} />

              {/* Swap page route */}
              <Route path="/swap" element={<SwapPage />} />
            </Routes>
          </Router>
        </RainbowKitProvider>
      </QueryClientProvider>
    </WagmiProvider>
  );
}

export default App;
