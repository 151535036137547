// src/contracts/routerContract.js
import { Contract, ethers } from 'ethers';
import { routerABI } from './routerABI';
import { wrapRouterABI } from './wrap.routerABI';

const getProvider = (signer) => {
  if (signer) return signer;
  // Create a read-only provider using Infura
  const provider = new ethers.JsonRpcProvider("https://mainnet.infura.io/v3/2QMx1h5rZc8j0Q3nBtnIukJTPfW");

  return provider;
}

export const getMainRouterContract = (signer) => {
  const contractAddress = '0xead811d798020c635cf8dd4ddf31bdc5595b09f3';
  const provider = getProvider(signer);
  return new Contract(contractAddress, routerABI, provider);
};

export const getWrapRouterContract = (signer) => {
  const contractAddress = '0x07fa3db821E7d20F80e7e1421Ac9638Bd5B50538';
  const provider = getProvider(signer);
  return new Contract(contractAddress, wrapRouterABI, provider);
};
