// src/services/fetchEthPrice.js
import { getMainRouterContract } from '../contracts/routerContract';

export const fetchEthPriceInUSDC = async (usdAmount, signer) => {
  if (!signer) return 0;

  try {
    const routerContract = getMainRouterContract(signer);
    const ethPriceInUSDC = await routerContract.usdcToEth(usdAmount);

    return ethPriceInUSDC;
  } catch (error) {
    console.error('Error fetching ETH price:', error);
    return 0;
  }
};
