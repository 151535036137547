import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';

// Import images from src/images
import heroImage from '../images/hero.png';
import h1Image from '../images/h1.webp';
import h3Image from '../images/h3.png';
import h6Image from '../images/h6.png';
import h7Image from '../images/h7.png';
import mainImage from '../images/main.png';
import tokenomicsImage from '../images/tokenomics.png';
import aboutImage from '../images/head.png';
import logoImage from '../images/favicon.png';

const LandingPageLayout = () => {
  return (
    <>
      <div className="hero__section">
        <Header />

        {/* Hero Section */}
        <div className="container">
          <div className="outer__hero">
            <div className="asc">
              <img src={heroImage} alt="hero" />
              <p>The OG Dog of Ether Vista</p>
              <ul>
                <li><a href="https://www.dextools.io/app/en/token/rover"><img src={h1Image} alt="h1" /></a></li>
                <li><a href="https://dexscreener.com/ethereum/0x6f77f6923203c0d057564649b3cd2c1ecd733d67"><img src={h3Image} alt="h3" /></a></li>
                <li><a href="https://x.com/VistaRover"><img src={h7Image} alt="h7" /></a></li>
                <li><a href="https://t.me/VistaRovererc20"><img src={h6Image} alt="h6" /></a></li>
              </ul>
            </div>
            <div className="media">
              <img src={mainImage} alt="frame" />
            </div>
          </div>
        </div>
      </div>

      <div className="divider"></div>

      {/* Tokenomics Section */}
      <div className="tokenomics__wrapper">
        <div className="container">
          <div className="outer__tokenomics">
            <div className="title">
              <h2 className="second-font">Tokenomics</h2>
            </div>
            <div className="main__area">
              <div className="media">
                <img src={tokenomicsImage} alt="tokenomics" />
              </div>
              <div className="desc">
                <div className="title-bar">
                  <div className="title-bar-text">$ROVER</div>
                </div>
                <div className="window-body has-space">
                  <div className="grid">
                    <div className="elem">
                      <span>TRADE FEE</span>
                      <p className="regular-font">$11/$11 paid in eth</p>
                    </div>
                 
                    <div className="elem">
                      <span>LIQUIDITY POOL</span>
                      <p className="regular-font">Burned to a Crisp</p>
                    </div>
                    <div className="elem">
                      <span>Contract Safety</span>
                      <p className="regular-font">Renounced</p>
                    </div>
                    <div className="elem">
                      <span>Total Supply</span>
                      <p className="regular-font">1,000,000,000</p>
                    </div>
                  </div>
                  <div className="address">
                    <span><img src={logoImage} alt="asset" /></span>
                    <div>
                      <span>Contract Address</span>
                      <p className="regular-font">0x6aF84e3e9Fa8486b5cBb67c55ED1E7D9372a6d23</p>
                    </div>
                  </div>
                  <div className="btn">
                    <a href="/swap">Grab Yourself Some Tokens</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="divider"></div>

      {/* Join Community Section */}
      <div className="join__community">
        <div className="container">
          <div className="outer__join">
            <div className="desc">
              <div className="title-bar">
                <div className="title-bar-text">About ROVERSWAP</div>
              </div>
              <div className="window-body has-space">
                <div className="media">
                  <img src={aboutImage} alt="timegif" />
                </div>
                <div className="aboutwrap"> 
                  <p>If you open the file browser on <strong>Windows Vista</strong>, you might find a small yellow dog on your screen.</p>

                  <p>His name is <strong>Rover</strong>, and you can put it anywhere you want!</p>

                  <p>It barks at you, wanders around and is quite neat.</p>

                  <p>He's the <strong>Mascot of Vista</strong>.</p>
                    <div className="btn">
                    <a href="https://t.me/VistaRovererc20">Join Rover's Community</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default LandingPageLayout;
